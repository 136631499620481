import {FC, Fragment, ReactNode, useEffect, useState} from 'react';
import {ReCaptchaProvider} from 'next-recaptcha-v3';

interface IProps {
    children: ReactNode;
    recaptchaActive: string;
    recaptchaKey: string
}

const ReCaptchaWrapper: FC<IProps> = ({children, recaptchaActive, recaptchaKey}) => {
    const [clientMount, setClientMount] = useState(<Fragment>{children}</Fragment>);
    useEffect(() => {
        setTimeout(() => {
            if (+recaptchaActive) {
                setClientMount(<ReCaptchaProvider useEnterprise={true} reCaptchaKey={recaptchaKey}>
                    {children}
                </ReCaptchaProvider>)
            }
        }, 2000)
    }, [recaptchaActive])
    return clientMount
}
export default ReCaptchaWrapper;